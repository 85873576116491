<template>
  <div>
    <div class="default-table">
      <ApeTable ref="apeTable"
                :data="dataList"
                :columns="columns"
                :loading="loadingStatus"
                :pagingData="pagingData"
                @row-contextmenu="openMenu"
                @switchPaging="switchPaging"
                @changeTableSort="changeTableSort"
                :summary-method="getSummaries"
                show-summary
                highlight-current-row
                border>
        <el-table-column prop="group_id" label="组别" min-width="100" align="center" show-overflow-tooltip
                         slot="first-column">
          <template slot-scope="scope">
            <span>{{ scope.row.group_name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="nickname" label="红人昵称" min-width="120" align="center" show-overflow-tooltip
                         slot="first-column" sortable="custom">
          <template slot-scope="scope">
            <artist-nickname :info="scope.row"/>
          </template>
        </el-table-column>
        <el-table-column prop="product_name" label="带货产品名" min-width="200" header-align="center" align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.product_name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="launch_date" label="上线日期" min-width="100" header-align="center" align="center"
                         sortable="custom" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.launch_date }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="售价" min-width="120" header-align="center" align="right" sortable="custom"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ moneyFormat(scope.row.price) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="sales_qty" label="销售量" min-width="120" header-align="center" align="right"
                         sortable="custom"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ numFormat(scope.row.sales_qty) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="month_amount" label="截至本月销售额" min-width="120" header-align="center" align="right"
                         sortable="custom" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ moneyFormat(scope.row.month_amount) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="end_date" label="统计截止时间" min-width="100"
                         header-align="center" align="center"
                         sortable="custom" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.end_date }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="录入时间" min-width="100" header-align="center" align="center"
                         sortable="custom" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.created_at }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="source_link" label="视频内容" min-width="80" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-link type="primary" :href="scope.row.source_link" target="_blank">
              下载
              <i class="el-icon-download el-icon--right"></i>
            </el-link>
          </template>
        </el-table-column>
        <!--        <el-table-column prop="created_at" label="创建时间" min-width="100" align="center" show-overflow-tooltip></el-table-column>-->
        <!--        <el-table-column prop="updated_at" label="修改时间" min-width="100" align="center" show-overflow-tooltip></el-table-column>-->
      </ApeTable>

      <!--    右键菜单-->
      <Contextmenu :menu-data="menuData" @change="handleCmd"/>

    </div>
  </div>
</template>

<script>
import ApeTable from "@/components/ApeTable"
import { numberFormat } from "@/utils/utils";
import Contextmenu from "./Contextmenu"
import { mapGetters } from "vuex";

export default {
  name: "CaseRecordList",
  components: {
    ApeTable,  Contextmenu
  },
  props: {
    searchCondition: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'userDptInfo'])
  },
  data() {
    return {
      loadingStatus: false,
      dataList: [],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        page_size: 20
      },
      orderSort: { id: 'desc' },//默认排序规则
      columns: [
        // {
        //   value: 'nickname',
        //   title: '红人昵称',
        //   width: 120,
        //   align: 'center',
        // },
        // {
        //   value: 'product_name',
        //   title: '带货产品名',
        //   width: 200,
        //   align: 'center',
        // },
        // {
        //   value: 'launch_date',
        //   title: '上线日期',
        //   width: 120,
        //   align: 'center',
        // },
        // {
        //   value: 'price',
        //   title: '售价',
        //   width: 120,
        //   align: 'right',
        // },
        // {
        //   value: 'sales_qty',
        //   title: '销售量',
        //   width: 120,
        //   align: 'right',
        // },
        // {
        //   value: 'month_amount',
        //   title: '截至本月销售额',
        //   width: 120,
        //   align: 'right',
        // },
        // {
        //   value: 'source_link',
        //   title: '视频内容',
        //   width: 120,
        //   align: 'center',
        // },
      ],
      //start:鼠标右键菜单
      menuData: {
        visible: false,
        top: 0,
        left: 0,
      },
      currentRow: {},
      currentColumn: {}
    }
  },
  watch: {
    searchCondition: {
      deep: true,
      handler() {
        // console.log('val',val)
        this.$refs['apeTable'].handleCurrentChange(1)
      }
    },

    // 'searchCondition.group_id'(val, oldVal) {
    //   if (val !== oldVal) {
    //     console.log('change group_id')
    //     this.$refs['apeTable'].handleCurrentChange(1)
    //   }
    // },
    // 'searchCondition.ym'(val, oldVal) {
    //   if (val !== oldVal) {
    //     console.log('change ym')
    //     this.$refs['apeTable'].handleCurrentChange(1)
    //   }
    // },
    // 'searchCondition.artist_id'(val, oldVal) {
    //   if (val !== oldVal) {
    //     // console.log('change artist')
    //     this.$refs['apeTable'].handleCurrentChange(1)
    //   }
    // },
  },
  methods: {
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '当前页合计：';
          return;
        }
        if (['month_amount', 'sales_qty'].indexOf(column.property) > -1) {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += ' ';
          } else {
            sums[index] = 'N/A';
          }

          switch (column.property) {
            case 'sales_qty':
              sums[index] = this.numFormat(sums[index])
              break
            case 'month_amount':
              sums[index] = '¥' + this.moneyFormat(sums[index])
              break
            default:
              // sums[index] = this.valFormat(sums[index])
              break
          }
        }

      });

      return sums;
    },
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.initList()
    },
    rateFormat(value) { // 金额 格式化
      return numberFormat(value, 2, '.', ',', 'round')
    },
    moneyFormat(value) { // 金额 格式化
      return numberFormat(value, 2, '.', ',', 'round')
    },
    numFormat(value) { // 数值 格式化
      return numberFormat(value, 0, '.', ',', 'round')
    },
    // 切换页码操作
    async switchPaging() {
      await this.initList()
    },
    async initList(type) {
      this.loadingStatus = true
      let pagingInfo = this.$refs['apeTable'].getPagingInfo(type)
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, pagingInfo, { order: this.orderSort })
      this.dataList = []
      let { list, pages } = await this.$api.getCaseRecordList(searchCondition)
      this.$nextTick(() => {
        this.dataList = list
      })
      this.pagingData.total = pages.total
      // this.offset = pages.offset
      this.loadingStatus = false
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      this.fileTitle = '案例记录数据'

      if (this.searchCondition.group_id) {
        condition.group_id = this.searchCondition.group_id
      }

      // if (this.searchCondition.artist_id) {
      //   condition.artist_id = this.searchCondition.artist_id
      // }

      if (this.searchCondition.ym) {
        let ymArr = this.searchCondition.ym.split('-')
        let day = new Date(parseInt(ymArr[0]), parseInt(ymArr[1]), 0);
        let daycount = day.getDate();//最后一天
        condition.start_time = this.searchCondition.ym + '-01'
        condition.end_time = this.searchCondition.ym + '-' + daycount
      }
      return condition
    },
    async copy() {
      if (this.currentRow.id) {
        let postData = { ...this.currentRow }
        delete postData.id
        // console.log('post data', postData)
        await this.$api.saveCaseRecord(postData)
        await this.initList()
      }

      // this.close()
    },
    handleCmd(cmd) {
      // this.$notify.info(`cmd:${ cmd }`)
      switch (cmd) {
        case 'copy':
          this.copy()
          break
        case 'edit':
          this.$emit('editRow', this.currentRow)
          break;
        case 'del':
          this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.delRow()

          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });
          });
          break;
        case 'log':
          // this.$message.warning('查看日志')
          this.$emit('showLog', this.currentRow)
          break;
      }
    },
    openMenu(row, column, e) {
      this.currentRow = row
      this.currentColumn = column
      if (this.userPermissions.indexOf('case_record_tiktok_enter') !== -1) {
        // const offsetWidth = this.$el.offsetWidth; // container width
        e.preventDefault();//阻止元素发生默认的行为

        const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
        // const offsetTop = this.$el.getBoundingClientRect().top; // container margin left
        this.menuData = {
          visible: true,
          left: e.clientX - offsetLeft,
          top: e.clientY
        }
        document.addEventListener('click', this.closeMenu) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
      }
    },
    //右键菜单：设置是否月考核
    closeMenu(e) {
      //未点击在 菜单上，则关闭
      if (e.target.className.indexOf('contextmenu__item') === -1) {
        this.menuData.visible = false;
        document.removeEventListener('click', this.closeMenu)
      }
    },
    async delRow() {
      await this.$api.delCaseRecord(this.currentRow.id)
      await this.initList()
    }
  },
  mounted() {
    // this.initList()
  }
}
</script>

<style scoped>

</style>
