<template>
  <div>
    <el-row>
      <el-col :span="22" :offset="1">
        <el-card>
          <div slot="header" class="clearfix" v-if="!editFormData.id">
            <!--        筛选条件-->
            <el-form label-width="90px" :inline="true">
              <el-form-item label="">
                <artist-search-custom :type="`case_record`" @handleSelect="selectArtist" :show-first-group="false" style="width: 100%"/>
              </el-form-item>
            </el-form>
          </div>
          <!--        表单-->
          <el-form :model="formData" label-width="120px" ref="elForm" :rules="rules" size="mini">
            <el-form-item label="红人昵称" prop="nickname">
              <el-input type="text" v-model="formData.nickname" placeholder="红人昵称" disabled clearable/>
            </el-form-item>
            <el-form-item label="带货产品名">
              <el-input type="text" v-model="formData.product_name" placeholder="产品名" :disabled="!editPermission"
                        clearable/>
            </el-form-item>
            <el-form-item label="上线日期">
              <el-date-picker
                  v-model="formData.launch_date"
                  type="date"
                  format="yyyy年MM月dd日"
                  value-format="yyyy-MM-dd"
                  placeholder="选择上线日期" :disabled="!editPermission" clearable>
              </el-date-picker>
            </el-form-item>
            <el-form-item label="售价">
              <el-input type="text" v-model="formData.price" placeholder="售价"
                        oninput="value=value.replace(/[^\d^\.^\-]/g,'')" :disabled="!editPermission"
                        clearable/>
            </el-form-item>
            <el-form-item label="销售量">
              <el-input type="text" v-model="formData.sales_qty" placeholder="销售量"
                        oninput="value=value.replace(/[^\d^\.^\-]/g,'')" :disabled="!editPermission" clearable/>
            </el-form-item>
            <el-form-item label="截至本月销售额">
              <el-input type="text" v-model="formData.month_amount" placeholder="截至本月销售额"
                        oninput="value=value.replace(/[^\d^\.^\-]/g,'')" :disabled="!editPermission" clearable/>
            </el-form-item>
            <el-form-item label="统计截止日期">
              <el-date-picker
                  v-model="formData.end_date"
                  type="date"
                  format="yyyy年MM月dd日"
                  value-format="yyyy-MM-dd"
                  placeholder="选择统计截止日期" :disabled="!editPermission" clearable>
              </el-date-picker>
            </el-form-item>
            <el-form-item label="视频内容">
              <el-input type="url" v-model="formData.source_link" placeholder="视频内容,网址 https://example.com"
                        :disabled="!editPermission" clearable/>
            </el-form-item>
            <el-form-item>
              <el-row>
                <el-col :span="12">
                  <el-button size="large" type="primary" @click="handleConfirm" :disabled="loadingCommit"
                             :loading="loadingCommit"
                             v-if="canEdit">
                    保存
                  </el-button>
                  <el-button size="large" type="default" :disabled="true" v-else>
                    保存(无权限)
                  </el-button>
                </el-col>
                <el-col :span="12" style="text-align: right">
                  <el-button size="large" type="default" :plain="true" v-if="canEdit&&formData.id"
                             @click="handleCmd('del')">
                    &nbsp;&nbsp;<i class="el-icon-delete"></i>删除 &nbsp;&nbsp;
                  </el-button>
                </el-col>
              </el-row>

            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import ArtistSearchCustom from "@/components/artist/ArtistSearchCustom";
export default {
  name: "CreateRecord",
  props: {
    editFormData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    ArtistSearchCustom
  },
  computed: {
    ...mapGetters(['userPermissions', 'userDptInfo']),

  },
  watch: {
    editFormData: {
      deep: true,
      handler(val) {
        // console.log('init2')
        //初始化变量
        this.initVar()
        if (val) {
          this.formData = { ...val }
          if (val.artist_id) {
            //分组信息
            this.getArtistInfo(val.artist_id)
          }
        }

      }
    }
  },
  data() {
    return {
      searchCondition: { artist_id: null, nickname: null, group_id: null, group_name: null },
      artists: [],
      formData: {
        artist_id: null,
        nickname: null,
        product_name: null,
        launch_date: null,
        price: null,
        sales_qty: null,
        month_amount: null,
        source_link: null
      },
      loadingCommit: false,
      rules: {
        nickname: [{
          required: true,
          message: '红人昵称不能为空',
          trigger: 'blur'
        }],
      },
      canEdit: true,
      editPermission: true,
      scopeType: 'all'
    }
  },
  methods: {
    init() {
      this.scopeType = this.calcScopeType()
    },
    initVar() {
      this.formData = {}
      this.searchCondition = { artist_id: null, nickname: null, group_id: null, group_name: null }
      this.editPermission = true
      this.artists = []
    },
    calcScopeType() {
      if (this.userPermissions.indexOf('case_record_data_company') > -1) {
        return 'all'

      } else if (this.userPermissions.indexOf('case_record_data_dpt') > -1) {
        // 当前选中的组在本部及其组，则有权限修改
        return 'dpt'

      } else if (this.userPermissions.indexOf('case_record_data_group') > -1) {
        // 当前选中的组在个人归属的组（一个人可在多组），则有权限修改
        return 'group'
      }
      return 'group'
    },
    close() {
      this.$emit('close')
    },
    handleConfirm() {
      this.$refs['elForm'].validate(valid => {
        if (!valid) return

        this.loadingCommit = true
        this.saveInfo()
      })
    },
    async saveInfo() {
      await this.$api.saveCaseRecord(this.formData)
      this.loadingCommit = false
      this.close()
    },
    doSearch() {
      // console.log('fm', this.formData)
      if (!this.editFormData.id) {
        this.formData = {}//真正实现数据更新的是这行代码
      }

      this.formData.nickname = this.searchCondition.nickname
      this.formData.artist_id = this.searchCondition.artist_id
      // console.log('change formData artist nickname', this.formData.nickname)
      this.canEdit = this.calcPermission(this.searchCondition.group_id)
    },
    async selectGroup(val) {
      this.searchCondition.group_id = val
      await this.getGroupArtists()

    },
    async changeArtist() {
      // 红人
      let artist = this.artists.find((value) => value.id === this.searchCondition.artist_id)
      this.searchCondition.nickname = artist ? artist.nickname : ''
      // console.log('search artist', this.searchCondition.nickname)
      this.doSearch()
    },
    async getGroupArtists(type) {
      this.searchCondition.artist_id = null
      this.searchCondition.nickname = null
      let groupId = this.searchCondition.group_id
      let { list } = await this.$api.getGroupArtists(groupId)
      this.artists = list
      if (type !== 'search' && list.length > 0) {
        this.searchCondition.artist_id = list[0].id
        await this.changeArtist()
      }
    },
    async selectArtist(selectInfo) {
      // console.log('si', selectInfo)
      this.searchCondition = { ...this.searchCondition, ...selectInfo }
      // 筛选
      this.doSearch()
    },

    //检索红人
    async chooseArtist(item) {
      if (item) {
        this.searchCondition.group_id = item.group_id
        this.searchCondition.group_name = item.group_name
        await this.getGroupArtists('search')
        this.searchCondition.artist_id = item.id
        this.searchCondition.nickname = item.nickname
        await this.changeArtist()
      } else {
        this.searchCondition.artist_id = null
        this.doSearch()
      }
    },
    //计算权限
    calcPermission(currentGroupId) {
      //录入权限
      let hasPermission = this.userPermissions.indexOf('case_record_tiktok_enter') > -1
      //范围权限
      let rangePermission = false

      // let currentGroupId = this.searchCondition.group_id
      //数据范围
      //有全公司 数据权限
      if (this.userPermissions.indexOf('case_record_data_company') > -1) {
        rangePermission = true

      } else if (this.userPermissions.indexOf('case_record_data_dpt') > -1) {
        // 当前选中的组在本部及其组，则有权限修改
        let dptRange = this.userDptInfo.dpt_range
        rangePermission = dptRange ? dptRange.indexOf(currentGroupId) > -1 : false

      } else if (this.userPermissions.indexOf('case_record_data_group') > -1) {
        // 当前选中的组在个人归属的组（一个人可在多组），则有权限修改
        let dpt_ids = this.userDptInfo.my_dpt_range ? this.userDptInfo.my_dpt_range : []
        rangePermission = dpt_ids ? dpt_ids.indexOf(currentGroupId) > -1 : false

      } else {
        rangePermission = false
      }

      // console.log(`组：${ this.searchCondition.group_id }, hasPermission:${ hasPermission }`)
      return hasPermission && rangePermission
    },
    async getArtistInfo(id) {
      let { info } = await this.$api.getArtistInfo(id)
      if (info) {
        await this.chooseArtist({
          id: info.id,
          nickname: info.nickname,
          group_id: info.group_id,
          group_name: info.group_name
        })
        //如果没有数据权限，则不能修改数据
        this.editPermission = this.calcPermission(info.group_id)
      }
    },
    async copy() {
      if (this.formData.id) {
        let postData = { ...this.formData }
        delete postData.id
        // console.log('post data', postData)
        await this.$api.saveCaseRecord(postData)
        this.loadingCommit = false
      }

      // this.close()
    },
    handleCmd(cmd) {
      switch (cmd) {
        case 'copy':
          this.copy()
          break
        case 'edit':
          // this.$emit('editRow', this.currentRow)
          break;
        case 'del':
          this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.delRow()

          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });
          });
          break;
      }
    },
    async delRow() {
      await this.$api.delCaseRecord(this.formData.id)
      this.close()
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
