<template>
  <div style="display: inline-block">
    <el-row class="searchCondition">
      <el-col :span="20">
        <el-form label-width="90px" v-on="$attrs" :inline="true">
          <el-form-item>
            <el-tooltip effect="dark" content="添加案例" placement="top-start">
              <el-button type="primary" size="medium" icon="iconfont icon-tianjiacaidan2" @click="addBtn"
                         style="display: inline-block"
                         v-if="userPermissions.indexOf('case_record_tiktok_enter') !== -1"></el-button>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="" prop="group_id">
            <DeptCascaderScope :type="`performance_data`" ref="refDeptCascaderScope"
                               @selectedDept="selectDept"/>
          </el-form-item>
          <el-form-item label="">
            <el-date-picker
                v-model="searchCondition.ym"
                type="month"
                placeholder="选择月度"
                format="yyyy年MM月"
                value-format="yyyy-MM"
                @change="doSearch">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-link class="op-link" type="primary" @click="showLog"
                     v-if="userPermissions.indexOf('case_record_log') !== -1">
              日志
              <i class="el-icon-document"></i></el-link>
          </el-form-item>

        </el-form>
      </el-col>
      <el-col :span="4" style="text-align: right">
        <!--        导出表格-->
        <export-link ref="refExportLink" :can-export="userPermissions.indexOf('case_record_export') !== -1"
                     @export="exportData"/>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import ArtistSearch from "@/components/artist/ArtistSearch";
import ExportLink from "@/components/export/ExportLink";
import { mapGetters } from "vuex";

export default {
  name: "SearchCommon",
  components: {
    ArtistSearch,
    ExportLink,
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      searchCondition: {
        group_id: null,
        group_name: null,
        artist_id: null,
        nickname: null,
        ym: null
      },
      artists: [],//可选红人列表
      showEdit: false
    }
  },
  methods: {
    defaultTime() {
      const end = new Date();
      //年份选择：默认当月
      // this.searchCondition.year = end.getFullYear()
      // this.searchCondition.month = end.getMonth() + 1
      this.searchCondition.ym = end.getFullYear() + '-' + (end.getMonth() + 1)
      this.doSearch()
    },
    doSearch() {
      // console.log('do search', this.searchCondition)
      this.$emit('doSearch', this.searchCondition)
    },
    /**
     * 部门选择：val-label
     * @param valArr
     * @param labelArr
     * @returns {Promise<void>}
     */
    async selectDept(valArr, labelArr) {
      let group_id = null;
      let group_name = null;
      if (valArr && valArr.length > 1) {
        let len = valArr.length;
        group_id = valArr[len - 1];
        group_name = labelArr[len - 1];
      }
      await this.setGroupInfo(group_id, group_name)
    },
    async setGroupInfo(group_id, group_name) {
      // this.$refs['refDeptCascaderScope'].initVal(group_id)
      this.searchCondition.group_id = group_id;
      this.searchCondition.group_name = group_name;
      this.doSearch()
    },
    addBtn() {
      // this.showEdit = true
      this.$emit('addBtn')
    },
    // 处理抽屉关闭
    drawerClose() {
      this.drawerData.visible = false
      this.drawerData.loading = true
      // this.initList()
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      this.fileTitle = '案例记录数据'

      if (this.searchCondition.group_id) {
        condition.group_id = this.searchCondition.group_id
      }

      // if (this.searchCondition.artist_id) {
      //   condition.artist_id = this.searchCondition.artist_id
      // }

      if (this.searchCondition.ym) {
        let ymArr = this.searchCondition.ym.split('-')
        let day = new Date(parseInt(ymArr[0]), parseInt(ymArr[1]), 0);
        let daycount = day.getDate();//最后一天
        condition.start_time = this.searchCondition.ym + '-01'
        condition.end_time = this.searchCondition.ym + '-' + daycount
      }
      return condition
    },
    //导出表格
    async exportData() {
      try {
        let searchCondition = this.handleSearchCondition()
        let response = await this.$api.exportCaseRecord(searchCondition)
        let name = `抖音视频案例`
        if (this.searchCondition.group_id) {
          name += `-${ this.searchCondition.group_name }`
        }
        if (this.searchCondition.ym) {
          name += `-${ this.searchCondition.ym }`
        }
        this.$refs.refExportLink.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
      }
    },
    showLog() {
      this.$emit('showLog')
    }

  },
  mounted() {
    this.defaultTime()
  }
}
</script>

<style scoped>

</style>
